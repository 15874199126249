"use strict";

/* main.js
======================================= */
var APP = function () {
  /* init
  --------------------------------------------- */
  var _init = function _init() {};
  /* _overlay_toggle
  --------------------------------------------- */


  var _overlay_toggle = function _overlay_toggle(element_id, event) {
    if (event) {
      if (!$(event.target).hasClass('overlay-ui')) {
        return false;
      }
    }

    $(element_id).toggleClass('overlay-open');
    $('body').toggleClass('overlay-showing');
  };
  /* public
  --------------------------------------------- */


  return {
    init: _init,
    overlay_toggle: _overlay_toggle
  };
}();

window.APP = APP;